<template>
  <div>
    <!--<div>
      <img src="/imgs/banner.png" style="width:100%;"/>
    </div>
    <div>
      <img src="/imgs/tips.png" style="width:100%;"/>
    </div>-->
    <h4 class="h-title">玛娅家居客户满意度调查</h4>
    <van-form @submit="onSubmit">
      <van-cell title="产品质量" class="row-group">
        <van-radio-group v-model="quality" direction="horizontal">
          <van-radio name="0">很满意</van-radio>
          <van-radio name="1">较满意</van-radio>
          <van-radio name="2">一般</van-radio>
          <van-radio name="3">非常不满意</van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell title="产品功能" class="row-group">
        <van-radio-group v-model="functions" direction="horizontal">
          <van-radio name="0">很满意</van-radio>
          <van-radio name="1">较满意</van-radio>
          <van-radio name="2">一般</van-radio>
          <van-radio name="3">非常不满意</van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell title="产品包装" class="row-group">
        <van-radio-group v-model="packages" direction="horizontal">
          <van-radio name="0">很满意</van-radio>
          <van-radio name="1">较满意</van-radio>
          <van-radio name="2">一般</van-radio>
          <van-radio name="3">非常不满意</van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell title="产品价格" class="row-group">
        <van-radio-group v-model="price" direction="horizontal">
          <van-radio name="0">很满意</van-radio>
          <van-radio name="1">较满意</van-radio>
          <van-radio name="2">一般</van-radio>
          <van-radio name="3">非常不满意</van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell title="产品交付及时性" class="row-group">
        <van-radio-group v-model="timely" direction="horizontal">
          <van-radio name="0">很满意</van-radio>
          <van-radio name="1">较满意</van-radio>
          <van-radio name="2">一般</van-radio>
          <van-radio name="3">非常不满意</van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell title="销售人员服务态度" class="row-group">
        <van-radio-group v-model="s_manner" direction="horizontal">
          <van-radio name="0">很满意</van-radio>
          <van-radio name="1">较满意</van-radio>
          <van-radio name="2">一般</van-radio>
          <van-radio name="3">非常不满意</van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell title="安装人员服务态度" class="row-group">
        <van-radio-group v-model="i_manner" direction="horizontal">
          <van-radio name="0">很满意</van-radio>
          <van-radio name="1">较满意</van-radio>
          <van-radio name="2">一般</van-radio>
          <van-radio name="3">非常不满意</van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell title="您是否愿意再次购买或转介绍" class="row-group">
        <van-radio-group v-model="recommend" direction="horizontal">
          <van-radio name="1">是</van-radio>
          <van-radio name="0">否</van-radio>
        </van-radio-group>
      </van-cell>
      <van-field
        class="row-group"
        v-model="suggestion"
        rows="2"
        autosize
        label="其它改进建议"
        type="textarea"
        maxlength="200"
        placeholder="请输入改进建议"
        show-word-limit
      />
      <van-field
        name="mobile"
        v-model="mobile"
        type="text"
        label="手机号"
        placeholder="请输入您的手机号"
        input-align="right"
      />
      <van-field
        v-model="code"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button size="small" type="info" native-type="button" id="send_code_btn" @click="sendCode()">发送验证码</van-button>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button block type="info" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Form, Button, Popup, Field, CellGroup, Cell, RadioGroup, Radio, Toast, Loading, Dialog } from 'vant'
export default {
  // 注册组件
  components: {
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Popup.name]: Popup,
    [Toast.name]: Toast,
    [Loading.name]: Loading,
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      quality: '',
      functions: '',
      packages: '',
      price: '',
      timely: '',
      s_manner: '',
      i_manner: '',
      recommend: '',
      suggestion: '',
      mobile: '',
      code: '',
      validFields: {
        quality: '产品质量',
        functions: '产品功能',
        packages: '产品包装',
        price: '产品价格',
        timely: '产品交付及时性',
        s_manner: '销售人员服务态度',
        i_manner: '安装人员服务态度',
        recommend: '您是否愿意再次购买或转介绍'
      },
      itv: '',
      scd: 60,
      showLoading: true
    }
  },
  created () {
    document.title = '玛娅家居客户满意度调查'
    document.querySelector('link[rel="icon"]').href = '/imgs/my_logo.png'
  },
  mounted () {
  },
  methods: {
    sendCode () {
      if (!(/^1\d{10}$/.test(this.mobile))) {
        Toast('请先输入正确的手机号')
      } else {
        document.getElementById('send_code_btn').disabled = 'true'
        document.getElementById('send_code_btn').classList.add('van-button--disabled')
        document.getElementById('send_code_btn').innerText = '发送中...'
        var that = this
        this.https.get('tools/questionnaire_code', { mobile: this.mobile }).then(response => {
          if (response.code === 0) {
            that.itv = setInterval(function () {
              if (that.scd > 1) {
                that.scd -= 1
                document.getElementById('send_code_btn').innerText = '等待' + that.scd + '(s)'
              } else {
                // document.getElementById('send_code_btn').disabled = 'false'
                document.getElementById('send_code_btn').removeAttribute('disabled')
                document.getElementById('send_code_btn').classList.remove('van-button--disabled')
                document.getElementById('send_code_btn').innerText = '发送验证码'
                that.scd = 60
                clearInterval(that.itv)
              }
            }, 1000)
          } else {
            document.getElementById('send_code_btn').removeAttribute('disabled')
            document.getElementById('send_code_btn').classList.remove('van-button--disabled')
            document.getElementById('send_code_btn').innerText = '发送验证码'
            that.scd = 60
            Toast(response.msg)
          }
        })
      }
    },
    onSubmit () {
      var valided = true

      var values = {
        quality: this.quality,
        functions: this.functions,
        packages: this.packages,
        price: this.price,
        timely: this.timely,
        s_manner: this.s_manner,
        i_manner: this.i_manner,
        recommend: this.recommend
      }
      for (var k in values) {
        if (values[k] === '') {
          Toast('请评价' + this.validFields[k])
          valided = false
          break
        }
      }
      if (!valided) {
        return false
      }
      if (this.code === '') {
        Toast('请输入验证码')
        valided = false
      }
      if (!(/^1\d{10}$/.test(this.mobile))) {
        Toast('请输入正确的手机号')
        valided = false
      }
      if (valided) {
        values.suggestion = this.suggestion
        values.mobile = this.mobile
        values.code = this.code
        this.submit(values)
      }
    },
    submit (params) {
      this.showLoading = true
      this.https.get('tools/questionnaire', params).then(response => {
        if (response.code === 0) {
          this.showLoading = false
          Dialog.alert({
            title: '提示',
            message: '成功提交满意度调查表，感谢你的支持！'
          }).then(() => {
            window.location.reload()
          })
        } else {
          Toast(response.msg)
        }
      })
    }

  }
}
</script>
<style>
  .h-title {
    color: #019384;
    height: 70px;
    line-height: 70px;
    margin-top:0;
    background: #fff url(/imgs/my_logo.png) no-repeat left top;
    background-size:contain;
  }
  .row-group {display: block; margin-bottom: 30px;}
  .row-group .van-cell__title {clear:both;display: block;margin-bottom: 5px;font-weight: bold;}
  .row-group .van-cell__value {clear:both;display: block;}
  .van-field__label span {font-weight: bold;}
  [class*=van-hairline]::after {border:none;}
  .van-button--info {
    background-color: #019384;
    border: 1px solid #019384;
  }
  .van-radio__icon--checked .van-icon {
    background-color: #019384;
    border-color: #019384;
  }
  .van-button--normal {
     font-size: 16px;
   }
  .van-cell:not(:last-child)::after {
    border: none;
  }
  .van-cell__title, .van-cell__value {
    text-align: left !important;
  }
  .van-stepper {
    text-align: right !important;
  }
</style>
